import logo from './logo.svg';
import './App.css';
import QuestionForm from './QuestionForm';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Ask any question related to contents of pdf provided in link below
        </p>
        <p>
        <a
          className="App-link"
          href="https://sanskrit.nic.in/data/Vision_and_Road_Map.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          VISION And ROAD MAP For the Development of Sanskrit - Ten year perspective Plan
        </a></p>
        <QuestionForm />
        
      </header>
    </div>
  );
}

export default App;
