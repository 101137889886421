import {useState} from 'react';
import axios from 'axios';
import {BounceLoader} from 'react-spinners';
import ReactMarkdown from 'react-markdown';

const api = axios.create({
    baseURL:'https://genaiapp-e46e4a1cbba8.herokuapp.com'
})

function QuestionForm() {
    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        setAnswer('');
        setIsLoading(true);
        e.preventDefault();
        console.log("Your question: ", question);
        const response = await api.post('/chat',{message: question});
        setAnswer(response.data.answer);
        setIsLoading(false);
    }

    return (
        <div className='main-container'>
           <form className='form'>
            <input className="form-input" type="text" value={question} onChange={(e)=> setQuestion(e.target.value)} />
            <button className="form-button" type="submit" onClick={handleSubmit}>Submit</button>
           </form>
           {isLoading && (
            <div className="loader-container">
                  <BounceLoader color="#3498db" />
            </div>
           )}
           {answer && (<div className='results-answer'>
            <h2>Answer:</h2>
            <ReactMarkdown>
                {answer}
            </ReactMarkdown>
           
           </div>)}
           <p>For a similar website contact ivdmahesh@gmail.com</p>
           </div>
    );
}

export default QuestionForm;